var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex justify-center items-start mb-16"},[_c('div',{staticClass:"w-full mb-2 simpleCard"},[_vm._l((_vm.mockup),function(data,index){return _c('div',{key:index},[(data && data.type === 'title' && data.title)?_c('h2',{staticClass:"section text-blue text-left customTitle"},[_c('b',[_vm._v(_vm._s(data.title))])]):_vm._e(),(data && data.type === 'accordion')?_c('div',{staticClass:"section accordionBox"},[_c('Accordion',{attrs:{"header":data.title}},[_c('div',{class:'boxValues'},_vm._l((data.content),function(item,index2){return _c('div',{key:index2,class:_vm.processStyles(
                  'boxText section',
                  item,
                  'width',
                  'section accordionBox'
                )},[(item && item.type === 'text' && item.title)?_c('p',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item && item.type === 'text' && item.subtitle)?_c('small',[_vm._v(_vm._s(item.subtitle))]):_vm._e(),(item && item.type === 'text' && item.value)?_c('div',{staticClass:"boxValue"},[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(item && item.type === 'file')?_c('a-icon',{style:({ fontSize: '1.6em' }),attrs:{"type":"bell","theme":"filled"}}):_vm._e(),(item && item.type === 'file')?_c('p',[_vm._v(_vm._s(item.title))]):_vm._e(),(item && item.type === 'file')?_c('button',[_c('a-icon',{style:({ fontSize: '1.6em' }),attrs:{"type":"bell","theme":"filled"}}),_c('p',[_vm._v("Descargar Archivo")])],1):_vm._e(),(item && item.type === 'accordion')?_c('Accordion',{attrs:{"header":item.title}},[_c('div',{class:'boxValues'},_vm._l((item.content),function(item2,index3){return _c('div',{key:index3,class:_vm.processStyles(
                        item2 && item2.type === 'text'
                          ? 'boxText section '
                          : 'boxFile section ',
                        item2,
                        'width',
                        'w-100'
                      )},[(item2 && item2.type === 'text' && item2.title)?_c('p',[_vm._v(" "+_vm._s(item2.title)+" ")]):_vm._e(),(item2 && item2.type === 'text' && item2.subtitle)?_c('small',[_vm._v(_vm._s(item2.subtitle))]):_vm._e(),(item2 && item2.type === 'text' && item2.value)?_c('div',{staticClass:"boxValue"},[_c('span',[_vm._v(_vm._s(item2.value))])]):_vm._e(),(
                        item2 &&
                        item2.type === 'newTracking' &&
                        _vm.showNewTracking
                      )?_c('div',{staticClass:"w-100"},[_c('BaseInput',{attrs:{"type":"date","label":"Fecha de registro"},model:{value:(_vm.newTrackingData.date),callback:function ($$v) {_vm.$set(_vm.newTrackingData, "date", $$v)},expression:"newTrackingData.date"}})],1):_vm._e(),(
                        item2 &&
                        item2.type === 'newTracking' &&
                        _vm.showNewTracking
                      )?_c('div',{staticClass:"w-100 mb-1"},[_c('BaseTextarea',{attrs:{"label":"Reporte de seguimiento de la DCF al plan de acción seleccionado.","type":"text","maxlength":"1000"},model:{value:(_vm.newTrackingData.descriptionReport),callback:function ($$v) {_vm.$set(_vm.newTrackingData, "descriptionReport", $$v)},expression:"newTrackingData.descriptionReport"}})],1):_vm._e(),(item2 && item2.type === 'newTracking')?_c('div',{staticClass:"mb-2 ps-2 w-100"},[_c('a-button',{staticClass:"flex items-start justify-start align-center items-center h-60",attrs:{"shape":"round","block":true},on:{"click":_vm.changeShowTracking}},[_c('span',{staticClass:"d-flex items-start justify-start align-center items-center font-bold"},[_c('a-icon',{staticClass:"font-normal mr-2",style:({
                              fontSize: '20px',
                              background: '#1890ff',
                              borderRadius: '25px',
                              padding: '5px',
                              color: 'white',
                            }),attrs:{"type":"plus"}}),_c('b',[_vm._v("Hacer nuevo seguimiento")])],1)])],1):_vm._e(),(item2 && item2.type === 'file')?_c('a-icon',{style:({ fontSize: '1.6em' }),attrs:{"type":"bell","theme":"filled"}}):_vm._e(),(item2 && item2.type === 'file')?_c('p',[_vm._v(" "+_vm._s(item2.title)+" ")]):_vm._e(),(item2 && item2.type === 'file')?_c('button',[_c('a-icon',{style:({ fontSize: '1.6em' }),attrs:{"type":"bell","theme":"filled"}}),_c('p',[_vm._v("Descargar Archivo")])],1):_vm._e()],1)}),0)]):_vm._e()],1)}),0)])],1):_vm._e()])}),(_vm.showNewTracking)?_c('div',{staticClass:"flex flex-row gap-3"},[_c('a-button',{attrs:{"type":"primary","shape":"round","block":true,"ghost":""},on:{"click":_vm.changeShowTracking}},[_vm._v("CANCELAR")]),_c('a-button',{attrs:{"type":"primary","shape":"round","block":true},on:{"click":_vm.submitNewTracking}},[_vm._v("AÑADIR SEGUIMIENTO")])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }